<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="user.avatar"
              :text="avatarText(user.name)"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ user.name }}
                </h4>
                <span class="card-text">{{ user.email }}</span>
              </div>
              <div class="d-flex flex-wrap">

                <b-button
                  @click="editUser()"
                  variant="primary"
                >
                  Modifier mon compte
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                {{ user.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Statut</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ user.state ? 'Activé' : 'Désactivé' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Rôle</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ user.user_group ? user.user_group.libelle : '...' }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ user.telephone }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-body>
        <b-card-title b-card-title>Permissions accordées au rôle</b-card-title>
        <b-badge v-for="(fonction, index) in user.fonctions"
                 :key="index" variant="light-primary" class="ma-1">
          {{ fonction.libelle }}
        </b-badge>
      </b-card-body>
    </b-card>
    <b-card v-if="user.user_group && user.user_group.libelle != 'Administrateur'" no-body>
      <b-card-body>
        <b-card-title b-card-title>Comptes auxquels j'ai accès</b-card-title>
        <b-badge v-for="(ressource, index) in user.resources"
                 :key="index" variant="light-primary" class="ma-1">
          {{ ressource.name }}
        </b-badge>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BCard, BCardTitle, BCardBody, BButton, BBadge, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BButton,
    BBadge,
    BRow,
    BCol,
    BAvatar,
  },
  computed: {
    ...mapState({
      user: state => state.user.auth,
    }),
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods:{
    editUser(){
      this.$router.push(
        {
          name : 'utisateur-edit',
          params: { id: '123' } 
        }
      ).catch(e=>gotCaught(e))
    }
  }
}
</script>

<style>
</style>
